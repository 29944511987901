<template>
  <div>
    <el-dialog class="custom-dialog" :title="'角色'+(formType?'编辑':'添加')" :visible.sync="dialogVisible" width="420px"
      :append-to-body="true" @close="closeAlert">
      <el-form ref="form" :rules="rules" :model="form" label-width="80px">
        <el-form-item label="角色名称" prop="roleName" class="custom-form-item">
          <el-input v-model="form.roleName" placeholder="请输入角色名称"></el-input>
        </el-form-item>
        <el-form-item label="角色编码" prop="roleCode" class="custom-form-item">
          <el-input v-model="form.roleCode" :disabled="formType" placeholder="请输入角色编码"></el-input>
        </el-form-item>
        <el-form-item label="父级角色" prop="roleParentCode" class="custom-form-item">
          <el-cascader class="width-100" :disabled="formType" :options="authOptions" v-model="form.roleParentCode"
            :props="{ checkStrictly: true,label:'title'}" clearable>
          </el-cascader>
        </el-form-item>
        <el-form-item label="角色备注" prop="roleRemarks" class="custom-form-item">
          <el-input type="textarea" v-model="form.roleRemarks" placeholder="请输入角色备注"></el-input>
        </el-form-item>
      </el-form>
      <div class="bottom-btn">
        <el-button type="primary" @click="onSubmit('form')" :loading="loadingAlert" :disabled="loadingAlert">确 定
        </el-button>
        <el-button @click="closeAlert">取 消</el-button>
      </div>
    </el-dialog>
    <el-row>
      <el-col :xs="24" :sm="24" :md="10" :lg="6" class="">
        <el-card class="custom-card mgAll10">
          <div slot="header" class="clearfix">
            <span class="border-l relative pdL10">角色列表</span>
          </div>
          <el-tree :data="authOptions" show-checkbox check-strictly @check-change="checkChange" default-expand-all
            node-key="value" ref="tree" highlight-current :props="defaultProps">
          </el-tree>
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="24" :md="14" :lg="18">
        <el-card class="custom-card mgAll10">
          <div slot="header" class="clearfix">
            <span class="border-l relative pdL10">{{$route.meta.title}}</span>
          </div>
          <div>
            <!-- 搜索 -->
            <el-input v-model="searchForm.name" placeholder="角色名称" class="mgB10 custom-form-input"></el-input>
            <el-button type="primary" @click="search" :loading="loading" :disabled="loading" plain class="mgL10 mgB10">
              搜索
            </el-button>
            <el-button type="success" @click="add" plain class="mgL10 mgB10">添加
            </el-button>
            <!-- 表格 -->
            <el-table :data="tableData" border v-loading="loading" row-key="id">
              <el-table-column prop="roleName" :show-overflow-tooltip="true" header-align="center" align="center"
                label="角色名称"></el-table-column>
              <el-table-column prop="roleCode" :show-overflow-tooltip="true" header-align="center" align="center"
                label="角色编码"></el-table-column>
              <el-table-column prop="roleParentCodeName" :show-overflow-tooltip="true" header-align="center"
                align="center" label="父级角色">
              </el-table-column>
              <el-table-column prop="roleRemarks" :show-overflow-tooltip="true" header-align="center" align="center"
                label="角色备注">
              </el-table-column>
              <el-table-column label="操作" width="180" header-align="center" align="center" fixed="right">
                <template slot-scope="scope">
                  <el-button @click.native.prevent="editRow(scope.row)" size="mini" class="custom-table-btn">
                    编辑
                  </el-button>
                  <el-button @click.native.prevent="authRow(scope.row)" type="primary" size="mini"
                    class="custom-table-btn">
                    赋权
                  </el-button>
                  <el-button @click.native.prevent="delRow(scope.row)" type="danger" size="mini"
                    class="custom-table-btn">
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <table-page :page="searchForm.page" :limit="searchForm.limit" :total="total" @search-table="feach">
            </table-page>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-dialog class="custom-dialog" title="权限分配" :visible.sync="authVisible" width="300px" :append-to-body="true"
      @close="closeAuthDialog">
      <el-tree :data="menuOptions" node-key="value" style="height: 300px;overflow: auto;" show-checkbox check-strictly
        @check="checkAuthChange" default-expand-all ref="authTree" highlight-current :props="defaultProps">
      </el-tree>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: "systemMenu",
    data() {
      return {
        tableData: [],
        loading: false,
        searchForm: {
          code: "",
          name: "",
          page: 1,
          limit: 10,
        },
        total: 0,
        menuOptions: [],
        authOptions: [],
        defaultProps: {
          children: 'children',
          label: 'title'
        },
        dialogVisible: false,
        formType: false,
        loadingAlert: false,
        form: {
          roleName: "",
          roleCode: "",
          roleParentCode: "",
          roleRemarks: ""
        },
        rules: {
          roleName: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          roleCode: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          roleParentCode: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          roleRemarks: [{
            required: false,
            message: '可为空',
            trigger: 'blur'
          }]
        },
        authVisible: false,
        authRoleInfo: {}
      }
    },
    mounted() {
      this.search();
      this.menuTreeAll()
      this.authTreeAll()
    },
    methods: {
      //根据角色id获取权限信息
      async getRoleList(id) {
        let res = await this.$axios.Post(this.$api.roleList, {
          roleId: id
        })
        this.$refs.authTree.setCheckedKeys(res.data)
      },
      //权限分配
      authRow(row) {
        this.authVisible = true;
        this.authRoleInfo = row
        this.getRoleList(row.roleId)
      },
      //菜单点击事件
      checkAuthChange(e, checkedNodes) {
        let current = checkedNodes.checkedKeys.findIndex(item => item === e.id);
        let state = current !== -1
        this.$axios.Post(this.$api.roleAuthAssign, {
          roleId: this.authRoleInfo.roleId,
          changeState: state,
          menuId: e.data_i.menuId
        }).then(() => {}).catch(err => {
          this.$refs.authTree.setChecked(e.id, !state)
          if (err.errMsg) {
            this.$message({
              type: 'error',
              message: err.errMsg
            });
          }
        })
      },
      //关闭
      closeAuthDialog() {
        this.authRoleInfo = {}
        this.$refs.authTree.setCheckedKeys([], false)
        this.authVisible = false;
         this.authTreeAll()
      },
      //复选框点击事件
      checkChange(e, e1) {
        let keys = this.$refs.tree.getCheckedKeys();
        keys.forEach(item => {
          if (item !== e.id) {
            this.$refs.tree.setChecked(item, false)
          }
        })
        this.searchForm.code = e1 ? e.id : '';
        this.search();
      },
      search() {
        this.getData(1, this.searchForm.limit)
      },
      getData(page, limit) {
        this.loading = true;
        this.$axios.Get(this.$api.roleListPage, {
          name: this.searchForm.name,
          code: this.searchForm.code,
          page: page,
          limit: limit
        }).then(res => {
          this.searchForm.limit = limit
          this.searchForm.page = page
          this.tableData = res.data;
          this.total = res.count
          this.loading = false;
        }).catch(() => {
          this.loading = false;
        })
      },
      feach(e) {
        this.getData(e.page, e.limit)
      },
      editRow(row) {
        this.formType = true;
        this.dialogVisible = true;
        let data = JSON.parse(JSON.stringify(row))
        let ids = data.roleCodes.substring(1, data.roleCodes.length - 1).split("],[")
        ids.splice(0, 1)
        data["roleParentCode"] = ids
        this.form = data
      },
      add() {
        this.dialogVisible = true;
      },
      //关闭
      closeAlert() {
        this.formType = false;
        this.dialogVisible = false;
        this.$refs["form"].resetFields();
        this.form.roleId = "";
        this.loadingAlert = false;
      },
      //表单提交
      onSubmit: function(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            console.log(this.form)
            this.formType ? this.editUser() : this.addUser()
          } else {
            return false;
          }
        });
      },
      //添加信息
      addUser() {
        this.loadingAlert = true
        let params = JSON.parse(JSON.stringify(this.form))
        params["roleParentCode"] = params.roleParentCode[params.roleParentCode.length - 1]
        this.$axios.Post(this.$api.roleAdd, params).then(() => {
          this.$message({
            type: 'success',
            message: '添加成功!'
          });
          this.closeAlert();
          this.getData(this.searchForm.page, this.searchForm.limit)
        }).catch((err) => {
          if (err.errMsg) {
            this.$message({
              type: 'error',
              message: err.errMsg
            });
          }
          this.loadingAlert = false
        })
      },
      //编辑信息
      editUser() {
        this.loadingAlert = true
        let params = JSON.parse(JSON.stringify(this.form))
        params["roleParentCode"] = params.roleParentCode[params.roleParentCode.length - 1]
        this.$axios.Post(this.$api.roleEdit, params).then(() => {
          this.$message({
            type: 'success',
            message: '修改成功!'
          });
          this.closeAlert();
          this.getData(this.searchForm.page, this.searchForm.limit)
        }).catch((err) => {
          if (err.errMsg) {
            this.$message({
              type: 'error',
              message: err.errMsg
            });
          }
          this.loadingAlert = false
        })
      },
      //删除表格数据
      delRow: function(row) {
        this.$confirm('确认要删除该条信息吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios.Post(this.$api.roleDel, {
            "id": row.roleId
          }).then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getData(this.searchForm.page, this.searchForm.limit)
          }).catch((err) => {
            if (err.errMsg) {
              this.$message({
                type: 'error',
                message: err.errMsg
              });
            }
          })
        }).catch(() => {});
      },
      //获取菜单数据
      menuTreeAll: function() {
        this.$axios.Get(this.$api.menuTreeAll, {}).then(res => {
          let data = res.data
          this.formatTree(data)
          this.menuOptions = data
        })
      },
      //获取数据
      authTreeAll: function() {
        this.$axios.Get(this.$api.roleTreeAll, {}).then(res => {
          let data = res.data
          this.formatTree(data)
          this.authOptions = data
        })
      },
      //格式化
      formatTree(data) {
        data.forEach(item => {
          item["value"] = item.id
          if (item.children.length > 0) {
            this.formatTree(item.children)
          } else {
            delete item.children
          }
        })
      }
    },
    components: {
      "table-page": () => import('@/components/TablePage')
    }
  }
</script>

<style lang="less">
  .custom-card+.custom-card {
    margin-top: 10px;
  }
</style>
